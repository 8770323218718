import React from 'react';

import './Guestlist.css'
import { Helmet } from 'react-helmet';

export const Guestlist: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Guestlist | Rose</title>
                <meta name="description" content="Give us the specifics on your visit and you’ll end up on our guestlist with free entrance until midnight." />
                <meta name="og:title" content="Guestlist | Rose" />
                <meta name="og:description" content="Give us the specifics on your visit and you’ll end up on our guestlist with free entrance until midnight." />
                <meta name="og:url" content="https://roseclub.se/guestlist" />
                <meta name="twitter:title" content="Guestlist | Rose" />
                <meta name="twitter:description" content="Give us the specifics on your visit and you’ll end up on our guestlist with free entrance until midnight." />
                <meta name="twitter:url" content="https://roseclub.se/guestlist" />
                <link rel="canonical" href="https://roseclub.se/guestlist" />
                <script src="https://venues.tablelistpro.com/widgets/embedded/tablelist-widgets-core.min.js" ></script> 
            </Helmet>
            <section className="guestlist">
                <div className="container mb-5">
                    <div className="guestlist__content mb-5 pb-5">
                        <h1 className="title fly-left">
                            Guestlist
                        </h1>
                        <p className="fly-bottom mb-5 pb-3">
                            Give us the specifics on your visit and you’ll end up on our guestlist with free entrance until midnight. Please note, the guestlist does not guarantee you access to Rose, it’s subject to availability.
                            <br /><br />
                            For larger groups, private events and other requests, please contact <a href="mailto:event@roseclub.se">event@roseclub.se</a>
                            <br /><br />
                            AGe limit 23 years.
                        </p>
                        <div data-tl-widget="inventory-list-widget" data-theme="theme-light" data-venue-id="99bef04158c9bb6d"></div>
                    </div>
                </div>
            </section>
        </>
    );
}
