import React from "react";
import { Helmet } from "react-helmet";

import './Lilys.css'

export const Lilys: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Lily's Club | Rose</title>
                <meta name="description" content="Discover Lily's, the club-in-club concept at Rose. A place of neon-lit luxury and timeless sounds, embodying easy-come happiness." />
                <meta property="og:title" content="Lily's Club | Rose" />
                <meta property="og:description" content="Discover Lily's, the club-in-club concept at Rose. A place of neon-lit luxury and timeless sounds, embodying easy-come happiness." />
                <meta property="og:url" content="https://roseclub.se/lilys" />
                <meta name="twitter:title" content="Lily's Club | Rose" />
                <meta name="twitter:description" content="Discover Lily's, the club-in-club concept at Rose. A place of neon-lit luxury and timeless sounds, embodying easy-come happiness." />
                <meta name="twitter:url" content="https://roseclub.se/lilys" />
                <link rel="canonical" href="https://roseclub.se/lilys" />
            </Helmet>
            <section className="lilys">
                <div className="container">
                    <div className="lilys__content">
                        <img src="/img/lilys.png" height="100" width="204"
                            className="fly-bottom"
                                alt="Lilys letters in red with white lights in the background"/>
                        <p className="fly-bottom">
                            As a part of rose, Lily's is a club-in-club concept and the daughter of Rose. She's so loved, you can feel it in the music. Lily's a place of easy-come happiness with the same luxurious interiors of her mother but with neon-lights combined with timeless sounds.
                        </p>
                        <img src="/img/lobby.jpeg"
                            className="lobby-image fade-in"
                            alt="Lobby of Lilys"/>
                    </div>
                </div>
            </section>
        </>
    );
}
