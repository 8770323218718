import React, { useState, useEffect } from 'react';
import './Home.css';
import { Landing } from './landing/Landing';
import { Helmet } from 'react-helmet';

const Home: React.FC = (): JSX.Element => {
    const images: string[] = [
        '/img/landing/1.webp', 
        '/img/landing/2.webp', 
        '/img/landing/3.webp', 
        '/img/landing/4.webp'
    ];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        if (currentImageIndex < images.length) {
            const timer = setTimeout(() => {
                setCurrentImageIndex(currentImageIndex + 1);
            }, 250); // 0.25 seconds
            return () => clearTimeout(timer);
        }
    }, [currentImageIndex, images.length]);

    return (
        <>
            <Helmet>
                <title>Home | Rose</title>
                <meta name="description" content="Welcome to Rose Club, an exquisite high-end nightclub with a decadent atmosphere. Discover our VIP tables, events, and vibrant happenings." />
                <meta property="og:title" content="Home | Rose Club" />
                <meta property="og:description" content="Welcome to Rose Club, an exquisite high-end nightclub with a decadent atmosphere. Discover our VIP tables, events, and vibrant happenings." />
                <meta property="og:url" content="https://roseclub.se" />
                <meta name="twitter:title" content="Home | Rose Club" />
                <meta name="twitter:description" content="Welcome to Rose Club, an exquisite high-end nightclub with a decadent atmosphere. Discover our VIP tables, events, and vibrant happenings." />
                <meta name="twitter:url" content="https://roseclub.se" />
                <link rel="canonical" href="https://roseclub.se" />
            </Helmet>
            <div className="home-wrap">
                <div className="image-container">
                    {images.map((url, index) => (
                        <img 
                            key={index} 
                            src={url} 
                            alt={`Rose black and white landing cover ${index}`} 
                            className={`image ${index === currentImageIndex ? 'active' : ''}`} 
                        />
                    ))}
                    {currentImageIndex === images.length && (
                        <div className={`landing-slide active`}>
                            <Landing />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Home;