import React, { useState } from 'react';
import './Vip.css';
import { Helmet } from 'react-helmet';
import useSendRoseEmail from '../../hooks/useSendEmail';
import { Loader } from '../../components/loader/Loader';

export const Vip: React.FC = (): JSX.Element => {

    const { sendEmail, loading, error, success } = useSendRoseEmail();

    const getTodayDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
    }    

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        guests: '',
        date: '',
        occasion: ''
    });

    const [validationErrors, setValidationErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        guests: '',
        date: '',
        occasion: ''
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Reset the validation error for the current field
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const validateForm = () => {
        const errors = {
            firstName: '',
            lastName: '',
            email: '',
            guests: '',
            date: '',
            occasion: ''
        };

        if (!formData.firstName) {
            errors.firstName = 'First name is required.';
        }

        if (!formData.lastName) {
            errors.lastName = 'Last name is required.';
        }

        if (!formData.email) {
            errors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email is invalid.';
        }

        // Validate guests
        if (!formData.guests.trim()) {
            errors.guests = 'Number of guests is required.';
        }
        
        if (parseInt(formData.guests) < 1) {
            errors.guests = 'Number of guests must be at least 1.';
        }

        // Validate date
        if (!formData.date.trim()) {
            errors.date = 'Date is required.';
        }

        // Validate occasion
        if (!formData.occasion.trim()) {
            errors.occasion = 'Occasion is required.';
        }

        setValidationErrors(errors);

        // Check if any errors exist
        return Object.values(errors).every(err => !err);
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            const emailSent = await sendEmail({
                type: 'vip',
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                message: formData.occasion,
                numberOfGuests: parseInt(formData.guests),
                date: formData.date
            });

            if (emailSent) {
                // Clear the form and errors
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    guests: '',
                    date: '',
                    occasion: ''
                });
                setValidationErrors({
                    firstName: '',
                    lastName: '',
                    email: '',
                    guests: '',
                    date: '',
                    occasion: ''
                });
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>VIP Table Reservation | Rose</title>
                <meta name="description" content="Book a VIP table at Rose and experience unparalleled service and entertainment. Celebrate in style with your exclusive spot in one of Stockholm's premier nightlife venues." />
                <link rel="canonical" href="https://roseclub.se/vip" />
                <meta property="og:title" content="VIP Table Reservation | Rose" />
                <meta property="og:description" content="Book a VIP table at Rose and experience unparalleled service and entertainment. Celebrate in style with your exclusive spot in one of Stockholm's premier nightlife venues." />
                <meta property="og:url" content="https://roseclub.se/vip" />
                <meta name="twitter:title" content="VIP Table Reservation | Rose" />
                <meta name="twitter:description" content="Book a VIP table at Rose and experience unparalleled service and entertainment. Celebrate in style with your exclusive spot in one of Stockholm's premier nightlife venues." />
                <meta name="twitter:url" content="https://roseclub.se/vip" />
            </Helmet>
            <section className="guestlist mb-5">
                <div className="container">
                    <div className="guestlist__content content">
                        <h1 className="title fly-left">
                            VIP Table
                        </h1>
                        <p className="fly-bottom">
                            Celebrating a friend’s birthday? A company milestone? Your cousin’s hamster’s annual crayfish party?
                            <br /><br />
                            USE THE FORM BELOW OR REACH OUT DIRECTLY USING WHATSAPP (DURING OPEN HOURS) <a href="https://wa.me/+46708155416" target="_BLANK" rel="noreferrer">+46 70 815 54 16</a>
                        </p>

                        <form onSubmit={handleFormSubmit} className="vip-form fly-bottom">
                            <div className="dual-wrapper">
                                <div className="first-name-wrapper w-100">
                                    <label htmlFor="firstName">First Name</label>
                                    <input type="text" 
                                        id="firstName"
                                        name="firstName" 
                                        placeholder="First Name" 
                                        value={formData.firstName} 
                                        onChange={handleInputChange}
                                        className={`form-control w-100 box-3d ${validationErrors.firstName ? 'is-invalid' : ''}`} />
                                    {validationErrors.firstName && <div className="invalid-feedback">{validationErrors.firstName}</div>}
                                </div>

                                <div className="last-name-wrapper w-100">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input type="text" 
                                        id="lastName"
                                        name="lastName" 
                                        placeholder="Last Name" 
                                        value={formData.lastName} 
                                        onChange={handleInputChange}
                                        className={`form-control w-100 box-3d ${validationErrors.lastName ? 'is-invalid' : ''}`} />
                                    {validationErrors.lastName && <div className="invalid-feedback">{validationErrors.lastName}</div>}
                                </div>
                            </div>

                            <label htmlFor="email">Email</label>
                            <input type="email" 
                                id="email"
                                name="email" 
                                placeholder="Email" 
                                value={formData.email} 
                                onChange={handleInputChange}
                                className={`form-control box-3d ${validationErrors.email ? 'is-invalid' : ''}`} />
                            {validationErrors.email && <div className="invalid-feedback">{validationErrors.email}</div>}
                            <div className="dual-wrapper">
                                <div className="w-100">
                                    <label htmlFor="guests">Number of Guests</label>
                                    <input type="number" 
                                        id="guests"
                                        name="guests" 
                                        placeholder="Number of Guests" 
                                        value={formData.guests} 
                                        onChange={handleInputChange}
                                        className={`form-control box-3d ${validationErrors.guests ? 'is-invalid' : ''}`} />
                                    {validationErrors.guests && <div className="invalid-feedback">{validationErrors.guests}</div>}
                                </div>
                                <div className="w-100">
                                    <label htmlFor="date">Date</label>
                                    <input type="date" 
                                        id="date"
                                        name="date" 
                                        value={formData.date} 
                                        onChange={handleInputChange}
                                        min={getTodayDate()}
                                        className={`form-control box-3d ${validationErrors.date ? 'is-invalid' : ''}`} />
                                    {validationErrors.date && <div className="invalid-feedback">{validationErrors.date}</div>}
                                </div>
                            </div>

                            <label htmlFor="occasion">Occasion</label>
                            <textarea id="occasion"
                                    name="occasion"
                                    placeholder="Tell us a little more ..."
                                    value={formData.occasion}
                                    rows={4}
                                    onChange={handleInputChange}
                                    className={`form-control box-3d ${validationErrors.occasion ? 'is-invalid' : ''}`}></textarea>
                            {validationErrors.occasion && <div className="invalid-feedback">{validationErrors.occasion}</div>}

                            <button type="submit" className="btn btn-default mt-3">Make a reservation</button>
                            {loading && <Loader />}
                            {success && <div className="alert alert-success mt-3">{success}</div>}
                            {error && <div className="alert alert-danger mt-3">{error}</div>}
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};
