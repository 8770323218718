import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

export const Navbar: React.FC = (): JSX.Element => {
	const [showNav, setShowNav] = React.useState<boolean>(false);
    const location = useLocation();

	const toggleNav = (): void => {
		setShowNav(!showNav);
	};

    const closeNavbar = () => {
        setShowNav(false);
    };

	const barClass = showNav ? 'active' : '';

	return (
		<header>
			<nav id="mobileNav" style={showNav ? {height: '100vh'} : {}}>            
                <div className="container">
                    <div className="mobile-nav-top">
                        <Link className="navbar-brand" to="/" onClick={closeNavbar}>
                        {location.pathname !== '/' && 
                            <img src="/img/logo/rose.png"
                                height="47"
                                alt="Rose logo"
                                className="pb-2" />
                        }
                        </Link>
                        <div className="burger-box" onClick={() => toggleNav()}>
                            <div className={`bar bar1 ${barClass}`}></div>
                            <div className={`bar bar2 ${barClass}`}></div>
                            <div className={`bar bar3 ${barClass}`}></div>
                        </div>
                    </div>
                    <div id="mobile-navbar" style={showNav ? {display: 'block'} : {display: 'none'}}>
                        <ul>
                            <li><Link to="/" onClick={closeNavbar}>Home</Link></li>
                            <li><Link to="/lilys" onClick={closeNavbar}>Lily's</Link></li>
                            <li><Link to="/guestlist" onClick={closeNavbar}>Guestlist</Link></li>
                            {/* <li><Link to="/new-years" onClick={closeNavbar}>New Year's</Link></li> */}
                            <li><Link to="/vip" onClick={closeNavbar}>VIP Table</Link></li>
                            <li><Link to="/contact" onClick={closeNavbar}>Contact</Link></li>
                        </ul>
                    </div>
                </div>
			</nav>
			<nav id="desktopNav">
                <div className="container">
                    <div id="nav-wrap">
                        <Link className="navbar-brand" to="/" onClick={closeNavbar}>
                        {location.pathname !== '/' && 
                            <img src="/img/logo/rose.png"
                                height="47"
                                alt="Rose logo"
                                className="pb-2" />
                        }
                        </Link>
                        <ul id="nav">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/lilys">Lily's</Link></li>
                            <li><Link to="/guestlist">Guestlist</Link></li>
                            {/* <li><Link to="/new-years">New Year's</Link></li> */}
                            <li><Link to="/vip">VIP Table</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>
			</nav>
		</header>
	);
}