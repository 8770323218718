import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import './NotFound.css'; 

const NotFound: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    return (
        <div className="not-found-wrap mt-5 pb-5 mb-5">
            <div className="container">
                <div className="col-lg-6 offset-lg-3 text-center">
                    <h1 className="title">Page Not Found</h1>
                    <p>Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
                    <button className="btn btn-default" onClick={() => navigate('/')}>
                        Go back to homepage
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
