import { useState } from "react";
import axios from "axios";
import { EmailData } from "../interfaces/EmailData";
import { UseSendEmailResponse } from "../interfaces/UseSendEmailResponse";

const BASE_API_URL = process.env.REACT_APP_API_URL || "https://jkm.solutions";

const useSendRoseEmail = (): UseSendEmailResponse => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const sendEmail = async (data: EmailData): Promise<boolean> => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(`${BASE_API_URL}/v1/email/sendRose`, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
      });

      setLoading(false);

      if (response.status === 200) {
        if (response.data && response.data.message)
          setSuccess(response.data.message);
        else
          setSuccess("Email sent successfully. We'll be in touch soon!");
        return true;
      } else {
        setError("Failed to send email. Please try again later.");
        return false;
      }
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.data && err.response.data.message)
        setError(err.response.data.message);
      else
        setError("An error occurred while sending the email. Please try again later.");
      return false;
    }
  };

  return { sendEmail, loading, error, success };
};

export default useSendRoseEmail;
