import React from 'react';
import { Link } from 'react-router-dom'

import './Footer.css';

export const Footer: React.FC = (): JSX.Element => {
    return (
        <footer className="pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-3">
                        <div className="footer-list">
                            <p>Hamngatan 2</p>
                            <p>111 47 Stockholm</p>
                            <p>
                                <a href="mailto:info@roseclub.se">
                                    info@roseclub.se
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="footer-list">
                            <p>Wednesday</p>
                            <p>22:00 - 03:00</p>
                            <p>Friday - Saturday</p>
                            <p>23:00 - 05:00</p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="footer-list">
                            <p>
                                <Link to="/contact">
                                    Lost & Found
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="footer-list">
                            <p>
                                <Link to="/guestlist">
                                    Guestlist
                                </Link>
                            </p>
                            <p>
                                <Link to="/vip">
                                    VIP Table
                                </Link>    
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}